import React from 'react';
import Header from './components/header/header';
import About from './components/about/about';
import Resume from './components/resume/resume';
import Footer from './components/footer/footer';

function App() {
  return (
    <div className="App">
      <Header />
      <About />
      <Resume />
      <Footer />
    </div>
  );
}

export default App;
