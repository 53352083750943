import React, { Component } from 'react';
export default class Resume extends Component {
  render() {
    return (
        <section id="resume">
        <div className="row work">
          <div className="three columns header-col">
            <h1><span>Projects</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
            <div className="twelve columns">
                <h3>Asset Workflow Tool Suite (Topology Eyewear)</h3>
                <p className="info">Lead Engineer <span>•</span> <em className="date">May 2021 - December 2022</em></p>
                <p>
                Tools used to digitize eyewear assets for use in virtual try-on application. The tools allow for assets 
                to move through a pipeline of several steps, providing automated checks, metrics, and reviewing capabilities 
                for various stakeholders to approve or request changes along the way. 
                </p>
              </div>
              <div className="twelve columns">
                <h3>Universal Access Layer (Discover Financial Services)</h3>
                <p className="info">Lead Engineer <span>•</span> <em className="date">March 2018 - May 2020</em></p>
                <p>
                A serverless AWS architecture designed to integrate with Discover's directory services to provide 
                fine grained access to the S3 data lake. Includes SQS, SNS, API Gateway, Lambda, Cloudwatch, DynamoDB, 
                and IAM resources. The project was built on the AWS console, then written in Terraform, then deployed onto 
                multiple AWS accounts through Jenkins with one-touch deployments and automated unit tests.
                </p>
              </div>
            </div>
          </div> 
        </div>
        <div className="row work">
          <div className="three columns header-col">
            <h1><span>Certifications</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                <h3>AWS Certified Solutions Architect - Associate</h3>
                <p className="info"><em className="date">October 2018</em></p>
              </div>
            </div>
          </div>
        </div>
        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                <h3>Loyola University Chicago</h3>
                <p className="info">B.B.A. in Information Systems, Minor in Computer Science <span>•</span> <em className="date">May 2017</em></p>
              </div>
            </div>
          </div>
        </div>
      </section> 
    );
  }
}